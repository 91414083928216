<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0" >
        <span @click="$router.go(-1)" >
          <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Crear usuario
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="createUser">
        <b-form
            class="mt-2"
            @submit.prevent="handleSubmit"
            @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col cols="12" class="mb-2" >
              <ImageDropzone
                  ref="images"
                  :files="files"
              ></ImageDropzone>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group
                  :label="'Nombre'"
                  label-for="account-name"
              >
                <b-form-input
                    v-model="name"
                    name="name"
                    :placeholder="'Nombre'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6" >
              <b-form-group
                  :label="'Apellidos'"
                  label-for="account-surname"
              >
                <b-form-input
                    v-model="surname"
                    :placeholder="'Apellidos'"
                    name="surname"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider
                  #default="{ errors }"
                  :name="'Email'"
                  rules="required|email|emailUnique"
              >
                <b-form-group
                    :label="'Email'"
                    label-for="account-e-mail"
                >
                  <b-form-input
                      v-model="email"
                      name="email"
                      :placeholder="'Email'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group
                  :label="'Telefono'"
                  label-for="account-phone"
              >
                <b-form-input
                    v-model="phone"
                    name="phone"
                    :placeholder="'Telefono'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                  :label="'Puesto de trabajo'"
                  label-for="account-job"
              >
                <b-form-input
                    v-model="job"
                    name="job"
                    :placeholder="'Puesto de trabajo'"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <validation-provider
                  #default="{ errors }"
                  :name="'Rol'"
                  rules="required"
              >
                <b-form-group
                    :label="'Rol'"
                    label-for="account-rols"
                >
                  <v-select
                      label="display_name"
                      :filterable="false"
                      :searchable="false"
                      :options="selectRoles"
                      placeholder="Selecciona un rol"
                      v-model="roles"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                  #default="{ errors }"
                  :name="'Contraseña'"
                  rules="required|min:8"
              >
                <b-form-group
                    :label="'Contraseña'"
                    label-for="account-password"
                >
                  <b-input-group>
                    <template #append>

                      <b-input-group-text>
                        <div
                            class="append-text"
                            @click="show1 = !show1"
                        >
                        <span>
                          <feather-icon
                              :icon="show1 ? 'EyeIcon' : 'EyeOffIcon'"
                              :svgClasses="[ 'text-white' ,'h-5 w-5 mt-2']"
                          ></feather-icon>
                        </span>
                        </div>

                      </b-input-group-text>

                    </template>
                    <b-form-input
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        placeholder="Contraseña"
                        hint="At least 8 characters"
                        counter
                        outlined
                        v-model="password"
                        class="w-full"
                    ></b-form-input>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  v-if="roles.name === 'super_admin'"
                  v-model="notifications"
                  class="d-inline-block mb-2 mt-1"
              >
                Recibir notificaciones
              </b-form-checkbox>
            </b-col>
            <b-col sm="12">
              <b-form-group
                  label="Departamentos"
                  label-for="account-departments"
              >
                <b-row>
                  <b-col
                      sm="4"
                      v-if="item.active"
                      :key="item.id"
                      v-for="item in departmentsSelect"
                  >
                    <b-form-checkbox
                        v-model="departments"
                        :value="item.id"
                    >
                      {{ item.name }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                  type="submit"
                  variant="primary"
                  class="mt-2 mr-1"
              >
                {{ 'Enviar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BFormCheckbox, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BInputGroupText,
    BInputGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      roles: { "id": 3, "name": "operario", "display_name": "Operario" },
      departments: [],
      files: [],
      profileFile: null,
      required,
      email,
      userPrepare: false,
      disabledClients: true,
      surname: '',
      name: '',
      phone: '',
      avatar: '',
      show1: false,
      password: '',
      job: '',
      notifications: false
    }
  },
  computed: {
    ...mapGetters({
      selectRoles: 'users/getRoles',
      departmentsSelect: 'departments/getSelectDepartments',
    }),
  },
  methods: {
    ...mapActions({
      create: 'users/create',
      selectDepartments: 'departments/selectDepartments',
      getRoles: 'users/getRoles',
    }),
    ...mapMutations({
      setSelectDepartments: 'departments/setSelectDepartments',
    }),
    handleSubmit() {
      this.$refs.createUser.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ user: formData })
          }
        }
      })
    },
    createFormData() {
      const data = this.$refs.images.getFormData()
      if (this.roles) {
        data.append('role', this.roles.name)
        if(this.roles.name != 'super_admin'){
          data.append('notifications', false)
        }else{
          data.append('notifications', this.notifications)
        }
      }
      if (this.departments.length > 0) {
        this.departments.forEach(element => {
          if (element && element !== null) {
            data.append('departments[]', element)
          }
        })
      } else {
        data.append('departments', '')
      }
      data.append('name', this.name)
      data.append('email', this.email)
      data.append('phone', this.phone)
      data.append('surname', this.surname)
      data.append('password', this.password)
      data.append('job', this.job)

      return data
    },
  },
  async created() {
    await this.selectDepartments({})
    await this.getRoles()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
